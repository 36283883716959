.bx--btn.bx--btn--icon-only {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mt-2 {
  margin-top: 2em;
}

.mt-3 {
  margin-top: 3em;
}

.height-full {
  height: 100%;
}

.stream-player {
  pointer-events: none;
}

.bx--chart-holder {
  background-color: transparent !important;
}

.plyr {
  height: 100%;
}

